<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute Group</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main   px-3 "
              @click="goto()"
            >
              Create
            </button>
          </div>
        </div>
        <!-- ------------------------ -->

        <div class="row m-0 px-3">
          <div class="col-12 col-xl-6">
            <div class="form-group">
              <label>CatCode</label>
              <div class="input-group input-group-sm mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="data.cattext"
                  @click="callModalcatcode()"
                />
                <div class="input-group-append">
                  <button class="input-group-text px-3" @click="Clearcatcode()">
                    x
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------------------------ -->
          <div class="col-12 col-xl-6">
            <div class="form-group">
              <label>Search</label>
              <div class="input-group input-group-sm   ">
                <div class="input-group-prepend input-group-sm">
                  <select class="custom-select" v-model="mode">
                    <option
                      v-for="(e, i) in select_mode"
                      :key="i"
                      :value="e.key"
                    >
                      {{ e.name }}
                    </option>
                  </select>
                </div>
                <input
                  type="search"
                  class="form-control form-control-sm"
                  placeholder="ค้นหา / Seach"
                  v-model="find"
                  v-on:keyup.enter="Search()"
                />
                <div class="input-group-append">
                  <button class="btn btn-sm bt-main" @click="Search()">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 px-3">
          <div class="col-8">
            <p style="font-weight: bold;">
              จำนวน&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
            </p>
          </div>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #cell(Mode)="row">
                <span
                  :class="[row.item.Mode == 'on' ? 'badge-bnb' : 'badge-twd']"
                  class="badge"
                  >{{ row.item.Mode.toUpperCase() }}</span
                >
              </template>
              <template #cell(active)="row">
                <span
                  :class="[row.item.active == 1 ? 'badge-main' : 'badge-twd']"
                  class="badge"
                  >{{ row.item.active == 0 ? "No" : "Yes" }}</span
                >
              </template>
              <template #cell(Action)="row">
                <span
                  type="button"
                  class="text-muted mx-2"
                  v-html="$svg_icon.edit"
                  @click="edit(row)"
                ></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div
                class="col-6 col-xl-1 input-group-prepend input-group-sm  mb-3"
              >
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3  mb-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalcatcode
      :key="reModalcatcode"
      confirmlvl="2"
      @return_data="return_data"
    />
  </div>
</template>

<script>
import Modalcatcode from "@/components/common/Modalcatcode.vue";
export default {
  name: "AttributesGroup",
  components: { Modalcatcode },
  data() {
    return {
      reModalcatcode: Date.now() + 5,
      mode: "labelTH",
      find: "",
      select_mode: [
        { name: "ค้นหาด้วย labelTH", key: "labelTH" },
        { name: "ค้นหาด้วย labelEN", key: "labelEN" },
      ],
      data: {
        cattext: "",
        catCode: "",
      },
      totalRows: 0,
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "No", label: "No", class: "text-center" },
        { key: "catCode", label: "catCode" },
        { key: "labelTH", label: "labelTH" },
        { key: "labelEN", label: "labelEN" },
        {
          key: "active",
          label: "active",
          class: "text-center",
        },
        {
          key: "Action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    "data.catCode": function(v) {
      this.getdata_list();
      this.find = "";
    },
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {
    this.getdata_list();
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },

    async getdata_list() {
      try {
        let { catCode } = this.data;
        let data = { catCode: catCode, attrGroupId: "" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_groups`,
          data,
          1
        );

        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    edit(data) {
      let { attrGroupId } = data.item;

      this.$router.push({
        name: "Attribute-group-Edit",
        query: { attrGroupId: attrGroupId },
      });
    },
    goto() {
      this.$router.push({ name: "Attribute-group-Create" });
    },

    callModalcatcode() {
      this.reModalcatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalcatcode");
      }, 100);
    },
    return_data(data) {
      let { catcode, selectnow } = data;
      this.data.cattext = selectnow;
      this.data.catCode = catcode;
    },
    Clearcatcode() {
      this.data.cattext = "";
      this.data.catCode = "";
    },
  },
};
</script>

<style></style>
